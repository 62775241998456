// Scss Document

.resources {
	
	

	.hero {

		margin-top: 43vw;
		margin-bottom: 13vw;

		h1 {
			font-size: 7.5vw;
			line-height: 8.5vw;
			margin-bottom: 12vw;
		}

	}


	#resources {
		
		margin-bottom: 25vw;

		h3 {
			text-align: left;
			font-size: 5.5vw;
			line-height: 6.75vw;
			font-weight: 900;
		}

		p {
			font-size: 4.25vw;
			line-height: 5.5vw;

			&.header {
				font-size: 5vw;
				line-height: 6.5vw;
				font-weight: bold;
				margin-bottom: 1vw;
			}

		}

		.download {
			margin: 10vw 0 14vw;

			.icon {
				width: 18vw;
				margin-bottom: 2vw;
			}

			a.button {
				max-width: 34vw;
			}

		}

	}


	/* ----------------------------------------------------------------------------------------------------------------*/	

	/* Desktop styles */
	@media screen and (min-width: $desktop) {

		.hero {
			margin-top: 97px;
			margin-bottom: 0;
			padding: 110px 0 135px;
			background-image: url("/assets/img/backgrounds/Resources-hero.jpg");

			.header-container {
				max-width: 575px;
			}
			
			h1 {
				font-size: 38px;
				line-height: 44px;
				margin: 0;
			}
		
		}
		
		#resources {
			
			margin-bottom: 100px;

			h3 {
				font-size: 24px;
				line-height: 30px;
				font-weight: bold;
				margin: 30px 0 15px;
				max-width: 750px;
			}

			p {
				font-size: 16px;
				line-height: 20px;

				&.header {
					font-size: 18px;
					line-height: 22px;
					margin-bottom: 10px;
				}

			}

			.download-row {
				display: flex;
				justify-content: space-between;
				align-items: flex-start;
				
				.download {
					margin: 62px 0 10px;
					width: 48%;
					
					display: flex;
					justify-content: flex-start;
					align-items: flex-start;
					
					.icon {
						width: 66px;
						margin-bottom: 0;
						margin-right: 20px;
					}

					a.button {
						max-width: 150px;
					}

				}
				
			}

		}
		

	}
	


}