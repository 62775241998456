// Scss Document

.benefits {
	
	.hero {
		padding-top: 4vw;
		margin-bottom: 5vw;
	}
	
	h1 {
		font-size: 8vw;
		line-height: 9vw;
		padding: 0 2vw;
		font-weight: 900;
	}
	
	.header-text {
		font-family: museo-slab, serif;
		color: #F15A38;
		font-weight: 900;
		font-size: 7vw;
		line-height: 9vw;
		margin-top: 10vw;
	}
	
	
	
	.image-header, .image-footer {
		font-family: museo-slab, serif;
		font-size: 5vw;
		line-height: 6.5vw;
		text-align: center;
		margin: 5vw 0;
	}
	
	.image-footer {
		margin: 5vw 10vw 10vw;
	}
	
	.people-graphic {
		margin: 0 auto 16vw;
	}
	
	.eighty {
		display: block;
		margin: 0 auto 7vw;
		width: 53vw;
	}
	
	#patient-outcomes {
		
		position: relative;
		
		#why {
			position: absolute;
			top: -33vw;
		}
		
		margin-bottom: 35vw;
		
		p {
			text-align: center;
		}
		
		a.button {
			margin-bottom: -15vw;
		}
		
		.callouts {
			margin-top: 0;
			
			div {
				margin-bottom: 14vw;
			}
			
			img {
				display: block;
				margin: 0 auto 5vw;
				width: 30vw;
			}
			
			
		}
		
	}
	
	
	#treatment-options {
		
		background-color: #228D96;
		
		.content {
			padding-bottom: 5vw;
		}
		
		.red-dot-left {
			background-image: url("/assets/img/backgrounds/orange-vertical-dots.png");
			background-position: left 5vw top;
			background-size: 5px auto;
			background-repeat: repeat-y;
			padding-left: 11vw;
			padding-top: 11vw;
		}
		
	}
	
	
	#annual-impact {
		padding: 17vw 2vw 8vw;
		
		.impact-text {
			
			p {
				font-family: museo-slab, serif;
				color: #228D96;
				text-align: center;
				font-size: 8.5vw;
				line-height: 9.5vw;
				margin: 15vw 0 19vw;
				font-weight: 900;
			}
			
		}
		
	}
	
	#ams-goals {
		
		position: relative;
		
		#ams {
			position: absolute;
			top: -30vw;
		}
		
		padding: 5vw 0 3vw;
		background-color: rgba(198, 235, 243, .24);
		
		.goal {
			
			margin: 14vw 0 18vw;
			padding: 0 4vw;
			
			p {
				text-align: center;
				
				&.goal-text {
					font-family: museo-slab, serif;
					font-weight: 900;
				}
				
				&.header {
					font-size: 5.5vw;
				}
				
			}
			
		}
		
	}
	
	
	
	
	/* Desktop styles */
	@media screen and (min-width: $desktop) {
		
		.hero {
			padding-top: 30px;
			padding-bottom: 50px;
			margin-bottom: 0;
			background-image: url("/assets/img/backgrounds/Benefits-hero-background.jpg");
			
			.header-container {
				max-width: 510px;
			}
			
		}

		h1 {
			font-size: 38px;
			line-height: 42px;
			padding: 0 0;
		}

		.header-text {
			font-size: 28px;
			line-height: 32px;
			margin-top: 35px;
		}

		.image-header, .image-footer {
			font-size: 18px;
			line-height: 24px;
			margin: 0 30px 45px;
		}

		.image-footer {
			margin: 20px 0 0;
		}

		.people-graphic {
			margin: 0 auto 20px;
			width: 387px;
		}

		.eighty {
			margin: 0;
			width: 192px;
			margin-right: 50px;
		}
		
		#patients {
			
			padding-bottom: 90px;
			
			.content {
				display: flex;
				justify-content: flex-start;
				align-items: flex-start;
				
				div.mistake {
					width: 47%;
					margin-right: 50px;
				}
				
				div.ten {
					width: 40%;
					margin-left: 50px;
				}
				
			}
			
			.blue-divider {
				width: 7px;
				height: auto;
				align-self: stretch;
				background-image: url("../img/backgrounds/blue-dots-vertical.png");
				background-size: 7px auto;
				background-position: top center;
				background-repeat: no-repeat;
				margin: 0 0;
			}
			
		}
		
		.grey-bar {
			
			margin-bottom: 65px;
			
			.content {
				
				display: flex;
				justify-content: center;
				align-items: center;
				
				div {
					max-width: 400px;
				}
				
			}
			
		}

		#patient-outcomes {

			margin-bottom: 75px;
			
			h3 {
				margin-top: 0;
				margin-bottom: 0;
			}

			a.button {
				max-width: 360px;
				margin: 0 auto -55px;
			}

			.callouts {
				margin-top: 0;
				margin-bottom: 30px;

				div {
					margin-bottom: 0;
					max-width: 450px;
					margin: 0 30px;
				}

				img {
					margin: 0 auto 10px;
					width: 80px;
				}


			}

		}


		#treatment-options {
			
			background-image: url("/assets/img/backgrounds/Patient-Doctor-background.jpg");
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;

			.content {
				padding-bottom: 0;
				
				.split {
					display: flex;
					justify-content: space-between;
					align-items: center;
					
					div {
						width: 50%;
					}
					
				}
				
			}

			.red-dot-left {
				background-image: none;
				padding: 25px 0;
				
				h4 {
					margin: 0 0 40px 60px;
				}
				
				p {
					margin-left: 60px;
				}
				
			}

		}


		#annual-impact {
			padding: 50px 0 10px;
			
			h3 {
				margin: 0 0 40px;
			}

			.impact-text {
				
				display: flex;
				justify-content: space-between;
				align-items: flex-start;				

				p {
					font-size: 32px;
					line-height: 36px;
					margin: 0 10px 50px;
					
					&.first {
						width: 25%
					}
					
					&.second {
						width: 35%;
					}
					
					&.third {
						width:  40%;
					}
					
				}

			}

		}

		#ams-goals {

			padding: 60px 0 30px;
			
			h3 {
				margin: 0 0 10px;
			}
			
			.goals {
				
				display: flex;
				justify-content: space-between;
				align-content: flex-start;
				
				.goal {
					
					width: 32%;
					margin: 30px 0 0;
					padding: 0 30px;
					
					img.check {
						margin-bottom: 10px;
					}

					p {

						&.header {
							font-size: 20px;
						}

					}

				}
				
			}
			
		}
	
	}
	
	
}