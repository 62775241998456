// main.scss

// Imports
@import 'config';
@import 'mobile';
@import 'nav';
@import 'desktop';
@import 'text';
@import 'benefits';
@import 'implementing';
@import 'resources';
@import 'faqs';