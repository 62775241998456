/* Scss Document */


h1 {
	font-family: museo-slab, serif;
	font-size: 11.75vw;
	line-height: 12vw;
	margin: 5vw 0 4vw;
	color: #228D96;
	font-weight: 900;
}

h2 {
	font-family: museo-slab, serif;
	font-size: 4.75vw;
	line-height: 5.75vw;
	margin: 0 0 4vw;
	color: #228D96;
}

h3 {
	font-family: museo-slab, serif;
	font-size: 7.5vw;
	line-height: 8.5vw;
	margin: 5vw 0 4vw;
	color: #F15A38;
	text-align: center;
}

h4 {
	font-family: museo-slab, serif;
	font-size: 7.25vw;
	line-height: 8.5vw;
	margin: 0 0 4vw;
	color: #ffffff;
}


h5 {
	font-family: museo-slab, serif;
	font-size: 5.5vw;
	line-height: 6vw;
	margin: 2vw 0 2vw;
	color: #228D96;
}

p, li {
	font-family: lato, sans-serif;
	font-size: 4.75vw;
	line-height: 6.25vw;
	margin: 0 0 4vw;
	color: #303334;
	font-weight: 600;
	
	&.references {
		font-size: 3.4vw;
		line-height: 4.3vw;
		word-break: break-word;
		color: black;
		strong {
			font-weight: 900;
		}
	}
	
	&.small {
		font-size: 4.5vw;
		line-height: 5.25vw;
		text-align: center;
		font-weight: normal;
	}
	
	.blactum{
		font-family: system-ui;
		font-weight: normal;
	}

	
}


th {
	font-family: lato, sans-serif;
	font-size: 5.25vw;
	line-height: 6.5vw;
	background-color: #A4C5CB;
	font-weight: bold;
	padding: 2vw;
	height: 10vw;
	text-align: center;
	
	&.dev {
		height: 8vw;
	}
}

td {
	font-family: lato, sans-serif;
	font-size: 5.25vw;
	line-height: 6.5vw;
	background-color: #ffffff;
	padding: 2vw;
	text-align: center;
	height: 10vw;
	
	&.alt {
		background-color: #E6EEF0;
	}
	
}



a {
	font-weight: bold;
	color: #143A63;
	text-decoration: none;
}

li {
	margin-bottom: 0;
}

ul {
	padding-left: 4.5vw;
}

a.button {
	font-family: museo-slab, serif;
	font-size: 3.75vw;
	line-height: 3.75vw;
	margin: 0 0 4vw;
	padding: 3.5vw;
	color: #ffffff;
	background-color: #F15A38;
	border-radius: 10vw;
	font-weight: 900;
	text-decoration: none;
	text-align: center;
	display: block;
}

sup {
	font-size: 55%;
	line-height: 0;
}

.nobreak {
	white-space: nowrap;
}

.orange {
	color: #D64321;
}

.blue {
	color: #228D96;
}

.white {
	color: #ffffff;
}


a.return {
	display: block;
	font-family: museo-slab, serif;
	font-size: 3vw;
	line-height: 3vw;
	font-weight: bold;
	color: #303334;
	text-decoration: none;
	padding-top: 15vw;
	text-align: center;
	margin: 5vw auto;
	max-width: 30vw;
	background-image: url("/assets/img/icons/Orange-Up-Arrow.png");
	background-position: top center;
	background-repeat: no-repeat;
	background-size: 10vw;
}





/* Desktop styles */
@media screen and (min-width: $desktop) {
	
	h1 {
		font-size: 46px;
		line-height: 52px;
		margin: 30px 0;
	}

	h2 {
		font-size: 18px;
		line-height: 22px;
		margin: 0;
	}

	h3 {
		font-size: 26px;
		line-height: 32px;
		margin: 50px 0 40px;
	}
	
	h4 {
		font-size: 26px;
		line-height: 32px;
		margin: 50px 0 40px;
	}

	h5 {
		font-size: 20px;
		line-height: 22px;
		margin: 10px 0 10px;
	}

	
	p, li {
		font-size: 18px;
		line-height: 24px;
		margin: 0 0 18px;

		&.references {
			font-size: 12px;
			line-height: 16px;
			word-break: normal;
		}
		
		&.small {
			font-size: 16px;
			line-height: 20px;
		}

	}
	
	li {
		margin-bottom: 0;
	}
	/*a {
		font-weight: bold;
		color: #143A63;
		text-decoration: none;
	}

	*/

	ul {
		padding-left: 14px;
	}

	a.button {
		font-size: 14px;
		line-height: 14px;
		margin: 0 0 14px;
		padding: 14px;
		padding-bottom: 13px;
		border-radius: 25px;
	}

	a.return {
		font-size: 12px;
		line-height: 12px;
		padding-top: 50px;
		margin: 20px 0 0 auto;
		max-width: 100px;
		background-size: 36px;
	}
	
	table {
		border-collapse: collapse;
	}
	
	th {
		font-size: 18px;
		line-height: 18px;
		padding: 20px;
		height: auto;
		border: 1px solid #4ba1aa;

		&.dev {
			height: auto;
		}
	}

	td {
		font-size: 15px;
		line-height: 18px;
		padding: 5px;
		height: 40px;
		border: 1px solid #4ba1aa;
	}


	
}