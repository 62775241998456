/* Scss Document */

body {
	margin: 0;
	padding: 0;
	
	&.locked {
		overflow: hidden;
	}
	
}


#container {
	width: 100%;
	background-color: #ffffff;
	/*padding-bottom: 25px;*/
}


.content {
	padding: 0 5vw;
	/*background-color: #ffffff;*/
	
	&.thin {
		padding: 0 9vw;
	}
	
}


.appear {
	transform: translateY(40px);
	opacity: 0;
	transition: all 500ms ease-out;
	
	&.on {
		transform: translateY(0px);
		opacity: 1;
	}
	
}


a {
	
	img {
		/* Necessary for the router to work properly if the developer puts an A tag around an IMG */
		pointer-events: none;
	}
	
}


img.full {
	display: block;
	width: 100%;
	height: auto;
}

.desktop-only {
	display: none;
}

/* REFERENCE */
#reference {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100vw;
	height: 1500vh;
	background-image: url("/assets/img/REFERENCE-mobile.jpg");
	background-size: 100% auto;
	background-position: top;
	background-repeat: no-repeat;
	opacity: 0.5;
	display: none;
}


/* HEADER */
header {
	position: fixed;
    top: 0px;
    background-color: white;
    padding-bottom: 2vw;
	z-index: 100000;
}

#top-links {
	background-color: #F15A38;
	border-radius: 5vw;
	padding: 1vw 17vw;
	text-align: center;
	margin-top: 2vw;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	
	p {
		font-size: 2.7vw;
		line-height: 3.2vw;
		color: white;
		display: inline;
		margin: 0;
		
		&#isi-link {
			display: none;
		}
		
		&#hcp {
			display: block;
			width: 100%;
		}
		
		a {
			color: white;
			text-decoration: none;
		}
		
	}
	
}


.hero {
	margin-bottom: 10vw;
	margin-top: 38vw;
}

#divider-dots {
	display: none;
}


/* HOME */
.home {
	
	h1 {
		margin-right: 10vw;
	}
	
	p.header-text {
		margin-bottom: 8vw;
	}
	
}

.callouts {
	margin-top: 27vw;
}

.callout {
	background-color: #f1fafc;
	border-radius: 15vw;
	padding-bottom: 15vw;
	margin-bottom: 30vw;
		
	img.icon {
		display: block;
		margin: 0 auto 3vw;
		width: 35vw;
		height: auto;
		position: relative;
		top: -16vw;
		
		+ p {
			margin: -16vw 7vw 6vw;	
		}
		
	}
	
	img.divider {
		display: block;
		width: 90%;
		margin: 4vw auto;
	}
	
	p {
		text-align: center;
		font-size: 5vw;
		margin: 4vw 7vw 6vw;
	}
	
	a.button {
		margin: 0 8vw;
		font-size: 4.5vw;
		line-height: 4.5vw;
		padding: 4.5vw;
	}
	
}

/* COMMON */
.blue-strip {
	background-color: #228D96;
	padding: 10vw 0;
	margin-bottom: 15vw;
	
	.content {
		background-color: transparent;
	}
		
	img.icon {
		display: block;
		width: 50vw;
		margin: 0 auto;
	}
	
	p {
		font-size: 5vw;
		line-height: 6.5vw;
		font-weight: bold;
		text-align: center;
		color: white;
		margin: 0 10vw 10vw;
	}
	
	a.button {
		color: white;
		border: 2px solid white;
		background-color: transparent;
		margin: 0 auto;
		font-size: 4.5vw;
		line-height: 4.5vw;
		padding: 2.5vw;
		max-width: 40vw;
	}
	
}

#references {
	margin-top: 16vw;
	padding: 0 2vw;
}



#isi {
	padding: 0 2vw;
	
	p, li {
		line-height: 5.4vw;
		font-weight: 400;
	}
	
	.content {
		position: relative;
		
		#isi-target {
			position: absolute;
			top: -15vw;
		}
		#risk-information {
			position: absolute;
			top: 105vw;
		}
		
	}
	
}

#static-isi {
	position: fixed;
	bottom: 0px;
	left: 0px;
	width: 100vw;
	height: 38vw;
	background-color: white;
	display: none;
}

footer {
	background-color: #143A63;
	padding: 8vw 0;
	
	#footer-content {
		margin: 8vw 2vw 0;
	}
	
	p {
		color: white;
		font-size: 3.8vw;
		margin-bottom: 2vw;
		
		&.copyright {
			font-size: 3.5vw;
			line-height: 5.25vw;
			margin-bottom: 0;
		}
				
	}
	
	a {
		color: white;
		margin: 0 2vw;
		
		&.no-left {
			margin-left: 0;
		}
		
		&.logo-link {
			display: block;
		}
		
	}
	
}


.blue-divider {
	width: 100%;
	height: 6px;
	background-image: url("/assets/img/backgrounds/blue-dots.png");
	background-position: center;
	background-repeat: repeat-x;
	background-size: auto 3px;
	margin: 10vw 0;
}

.grey-bar {
	background-color: rgba(236, 240, 244, .86);
	padding: 10vw 0 5vw;
	margin-bottom: 10vw;
}


.rounded-blue {
	border: 15px solid transparent;
	padding: 5vw;
	border-radius: 10vw;
	border-image: url("/assets/img/patterns/dotted-outline.png") 40 round;
}

img.check {
	display: block;
	width: 25vw;
	margin: 0 auto 3vw;
}

#guidelines {

	background-color: #228D96;
	padding: 20vw 0;

	background-image: url("/assets/img/backgrounds/white-dots.png"), url("/assets/img/backgrounds/white-dots.png");
	background-position: top 25vw left, bottom 25vw right;
	background-repeat: no-repeat;
	background-size: auto 5px, auto 5px;

	p {
		font-family: museo-slab, serif;
		color: #ffffff;
		font-size: 5vw;
		line-height: 6.5vw;
		margin: 15vw 0;
		font-weight: 900;
	}

}


#pdfs, #pdfs2 {

	padding: 30vw 0 0;

	.icon {
		display: block;
		width: 22vw;
		margin: 0 auto 4vw;

		&.syringe {
			width: 30vw;
			margin-bottom: 8vw;
		}

	}

	.pdf-link {
		border: 15px solid transparent;
		padding: 5vw 2vw;
		border-radius: 10vw;
		border-image: url("/assets/img/patterns/pdf-outline.png") 30 round;

		margin-bottom: 15vw;

		p {
			font-weight: 400;
			text-align: center;
			margin-bottom: 10vw;
		}

		a.button {
			margin-bottom: 0;

			&.learn {
				max-width: 40vw;
				margin: 0 auto;
			}

		}

	}

}


.error-404 {
	
	.hero {
		margin-top: 30vw;
		padding: 5vw 0;
		background-color: #FEEBE7;
		
		img.icon {
			max-width: 50vw;
		}
		
		a.button {
			max-width: 50vw;
		}
		
	}
	
}

.error-500 {
	
	.hero {
		margin-top: 30vw;
		padding: 5vw 0;
		background-color: #FEEBE7;
		
		img.icon {
			max-width: 70vw;
		}
		
	}
	
}




