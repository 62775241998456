/* Scss Document */

/* Mobile and default styles */

#nav-bar {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	margin-top: 2vw;
	
	#pre-pen-logo {
		width: 60vw;
		height: auto;
		margin-left: 2.5vw;
	}
	
}


nav {
	height: 0;
	position: absolute;
	top: 30vw;
	left: 0vw;
	width: 100vw;
}

nav a {
	font-family: "Museo Slab", sans-serif;
	font-size: 5vw;
	line-height: 6vw;
	color: #228D96;
	text-decoration: none;
	padding: 2vw 0;
	margin: 0 20px;
	display: block;
	
	&.sub {
		color: #303334;
		font-size: 4vw;
		line-height: 5.5vw;
	}
	
}

/* page indicators */
body.benefits a#nav-benefits {
	font-weight: bold;
}

body.implementing a#nav-implementing {
	font-weight: bold;
}

body.resources a#nav-resources {
	font-weight: bold;
}

body.faqs a#nav-faqs {
	font-weight: bold;
}


#nav-buttons {
	background-color: #F0F9FB;
	height: 0;
	overflow: hidden;
	/*position: absolute;
	top: 16vw;
	background-color: #f0cb82;*/
	
	&.active {
		
		height: 105vh;
		padding: 4vw 0;
		
		a {
			display: block;
		}

	}
	
}

#mobile-button {
	width: 12vw;
	height: 12vw;
	position: absolute;
	top: 15vw;
	right: 4vw;
	background-image: url("/assets/img/icons/Open-Icon_Mobile_2x.png");
	background-size: 80%;
	background-position: center;
	background-repeat: no-repeat;
}

/* Styles for when the nav is open */
#mobile-button.active {
	background-image: url("/assets/img/icons/Close-Icon_Mobile_2x.png");
	
	&+nav #nav-buttons {
		height: 105vh;
		padding: 4vw 0;
		
		a {
			display: block;
		}
		
	}
	
	
}



/* Desktop styles */

@media screen and (min-width: $desktop) {
	
	#mobile-button {
		display: none;
	}
	
	#nav-bar {
		margin-top: 0px;
		align-items: center;

		#pre-pen-logo {
			width: 315px;
			margin-left: 0;
			margin-top: 15px;
		}

	}
	
	nav {
		height: auto;
		position: static;
		max-width: 650px;
		padding: 50px 0 13px 50px;
		background-color: #F15A38;
		border-bottom-left-radius: 100px;
	}
	
	nav a {
		font-family: lato, sans-serif;
		font-weight: 500;
		font-size: 15px;
		line-height: 18px;
		padding: 2px 0;
		margin: 0 0;
		text-align: center;
		color: white;
		
		&.sub {
			display: none;
		}
		
		&#nav-faqs {
			margin-left: 15px;
			margin-top: -20px;
		}
		
	}
	
	nav a:first-child {
		margin-left: 0;
	}

	#nav-buttons {
		height: auto;
		position: static;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: transparent;
	}

}