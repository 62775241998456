// Scss Document

.implementing {
  .hero {
    margin-top: 36vw;
    margin-bottom: 7vw;

    h1 {
      font-size: 8vw;
      line-height: 9vw;
      margin: 4vw 4vw 0 2vw;
    }
  }

  .align-top {
    margin-top: -24px;
  }

  #features {
    padding: 0 0 3vw;

    .feature {
      margin: 14vw 0 18vw;
      padding: 0 4vw;
    }
  }

  #guidelines {
    padding: 22vw 0 15vw;
    margin-bottom: 20vw;
    background-position: top 25vw left, bottom 20vw right;
  }

  #reduced-use {
    margin-bottom: 12vw;

    .dotted {
      border: 15px solid transparent;
      padding: 4vw;
      padding-right: 0;
      border-radius: 10vw;
      border-image: url("/assets/img/patterns/reduced-use-mobile.png") 40 round;
    }

    h3 {
      text-align: left;
      font-size: 7vw;
      margin-bottom: 9vw;
    }

    .icon {
      display: block;
      width: 57vw;
      margin: 0 auto 4vw;
    }

    p.drug-name {
      font-family: museo-slab, serif;
      font-weight: bold;
      font-size: 7vw;
      line-height: 7.5vw;
      margin-bottom: 20vw;
      color: #303334;
      text-align: center;

      &.last {
        margin-bottom: 10vw;
      }
    }

    .percents {
      padding-right: 4vw;
    }
  }

  #how-to-test {
    position: absolute;
    margin-top: -33vw;
  }

  #testing-supplies {
    margin-bottom: 20vw;

    h3 {
      text-align: left;
    }
  }

  #testing-steps {
    margin-bottom: 15vw;

    h3 {
      text-align: left;
      margin-bottom: 2vw;
    }

    p {
      font-weight: normal;

      &.header {
        font-weight: 900;
        font-size: 4.25vw;
        line-height: 5.5vw;
      }
    }

    li {
      font-weight: normal;
    }

    ul {
      ul {
        padding-left: 0;

        li {
          list-style: none;
          text-indent: -4vw;
          padding-left: 4vw;

          &:before {
            content: "– ";
          }
        }
      }
    }

    #expand-all {
      margin-top: 10vw;
    }

    .expand-button {
      font-family: museo-slab, serif;
      color: white;
      font-size: 6.5vw;
      line-height: 6.5vw;
      padding: 10vw 10vw 10vw 5vw;
      background-color: rgba(34, 141, 150, 0.7);
      font-weight: bold;
      margin: 0 -5vw 20vw -5vw;
      background-image: url("/assets/img/icons/plus.png");
      background-position: right 5vw center;
      background-size: 10vw;
      background-repeat: no-repeat;

      &.expanded {
        background-image: url("/assets/img/icons/minus.png");
        margin-bottom: 0;

        + .test-content {
          display: block;
        }
      }
    }

    .test-content {
      padding: 5vw;
      margin: 0 -5vw;
      background-color: rgba(34, 141, 150, 0.08);
      margin-bottom: 20vw;
      display: none;
    }
  }

  #pdfs {
    padding: 0;
    margin-bottom: 25vw;
  }

  #need-help {
    position: relative;

    #help {
      position: absolute;
      top: -30vw;
    }

    .help-content {
      padding: 10vw 5vw 5vw;
      margin: 0 -5vw;
      background-color: #228d96;

      .orange-help-dots {
        background-image: url("/assets/img/backgrounds/orange-help-dots.png");
        background-position: left center;
        background-repeat: no-repeat;
        background-size: auto 5px;
        height: 5px;
        margin: 5vw 0 5vw -5vw;
      }

      p {
        text-align: left;
      }

      a {
        color: #fff;
        font-weight: 600;
        text-decoration: underline;
      }
    }
  }

  /* ----------------------------------------------------------------------------------------------------------------*/

  /* Desktop styles */
  @media screen and (min-width: $desktop) {
    .hero {
      margin-top: 102px;
      margin-bottom: 0;
      padding: 70px 0 135px;
      background-image: url("/assets/img/backgrounds/Implementing-hero-background.jpg");

      .header-container {
        max-width: 575px;
      }

      h1 {
        font-size: 38px;
        line-height: 44px;
        margin: 0;
      }
    }

    #features {
      padding: 0 0 30px;

      .features {
        display: flex;
        justify-content: space-between;
        align-content: flex-start;

        .feature {
          width: 32%;
          margin: 30px 0 0;
          padding: 0 10px;

          img.check {
            margin-bottom: 14px;
          }

          p {
            text-align: center;
            font-size: 16px;
            line-height: 20px;
          }

          &.first {
            width: 28%;
          }

          &.second {
            width: 28%;
          }

          &.third {
            width: 28%;
          }
        }
      }
    }

    #guidelines {
      padding: 60px 0 70px;
      margin-bottom: 80px;
      background-position: top 60px left, bottom 60px right;

      p {
        max-width: 900px;
      }
    }

    #reduced-use {
      margin-bottom: 60px;

      .dotted {
        border: 15px solid transparent;
        padding: 20px 20px 12px;
        border-radius: 50px;
        border-image: url("/assets/img/patterns/reduced-use-desktop.png") 35
          round;
        -moz-border-image: url("/assets/img/patterns/reduced-use-desktop.png")
          27 round;
        -webkit-border-image: url("/assets/img/patterns/reduced-use-desktop.png")
          27 round;
        -o-border-image: url("/assets/img/patterns/reduced-use-desktop.png") 27
          round;
      }

      h3 {
        text-align: center;
        font-size: 26px;
        margin: 0 auto 30px;
        max-width: 750px;
      }

      .icon {
        width: 144px;
        margin: 0 auto 10px;
      }

      p.drug-name {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 0px;

        &.last {
          margin-bottom: 10px;
        }
      }

      .percents {
        padding-right: 0;

        display: flex;
        justify-content: space-between;
        align-items: flex-start;
      }
    }

    #testing-supplies {
      margin-bottom: 110px;

      h3 {
        text-align: left;
        margin-bottom: 15px;
      }
    }

    #testing-steps {
      margin-bottom: 50px;

      h3 {
        margin-bottom: 15px;
      }

      p {
        &.header {
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 4px;
        }
      }

      ul {
        ul {
          li {
            text-indent: -10px;
            padding-left: 10px;
          }
        }
      }

      #expand-all {
        margin-top: 0;
        position: absolute;
        right: 25px;
        top: 50px;
        cursor: pointer;
      }

      .expand-button {
        font-size: 24px;
        line-height: 24px;
        padding: 37px 25px;
        margin: 0 0 40px 0;
        background-position: right 25px center;
        background-size: 40px;
        cursor: pointer;
      }

      .test-content {
        padding: 25px;
        padding-bottom: 12px;
        margin: 0 0;
        margin-bottom: 40px;
      }
    }

    #pdfs {
      margin-bottom: 100px;

      .pdf-link {
        max-width: 350px;
      }
    }

    #need-help {
      background-image: url("/assets/img/backgrounds/Speaker-Image-backround.jpg");
      background-size: cover;
      background-position: center;

      .split {
        display: flex;
        justify-content: space-between;
        align-items: center;

        div {
          width: 50%;
        }
      }

      .help-content {
        padding: 35px 35px 35px 0;
        margin: 0;
        background-color: transparent;

        h4 {
          font-size: 24px;
          margin-top: 0;
        }

        p {
          font-size: 16px;
          line-height: 20px;
        }

        .orange-help-dots {
          display: none;
        }

        a {
          color: #fff;
          font-weight: 600;
          text-decoration: underline;
        }
      }
    }

    #pdfs2 {
      .pdf-row {
        margin-bottom: 105px;
      }

      .pdf-download {
        .pdf-link {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: auto;
          max-width: 880px;
          padding: 30px 50px 20px;
          margin: 0 auto 50px;

          border-image: url("/assets/img/patterns/flashcard-border.png") 30
            round;
          -moz-border-image: url("/assets/img/patterns/flashcard-border.png") 27
            round;
          -webkit-border-image: url("/assets/img/patterns/flashcard-border.png")
            27 round;
          -o-border-image: url("/assets/img/patterns/flashcard-border.png") 27
            round;

          img {
            margin-bottom: 0;
            width: 10%;
            margin-right: 15px;
          }

          p {
            width: 45%;
            margin-bottom: 0;
          }

          a.button {
            width: 45%;
            margin-left: 15px;
          }
        }
      }
    }
  }
}
