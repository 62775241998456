// Scss Document

.faqs {
	
	.hero {

		margin-top: 43vw;
		margin-bottom: 13vw;

		h1 {
			font-size: 7.5vw;
			line-height: 8.5vw;
			margin-bottom: 12vw;
		}

	}

	#questions {
		
		margin-bottom: 20vw;
		
		h3 {
			text-align: left;
			font-size: 6vw;
			line-height: 7.5vw;
			margin-bottom: 15vw;
		}
		
		.content {
			position: relative;
			
			#expand-all {
				position: absolute;
				right: 5vw;
				top: 28vw;
				cursor: pointer;
			}
			
			.expand-button {
				font-family: museo-slab, serif;
				color: white;
				font-size: 5vw;
				line-height: 6.5vw;
				padding: 8vw 10vw 10vw 8vw;
				background-color: rgba(34, 141, 150, 0.7);
				font-weight: bold;
				margin: 0 0 4vw 0;
				background-image: url("/assets/img/icons/plus.png");
				background-position: right 7vw center;
				@media only screen and (max-width: 600px) {
					background-position: right 2vw center;
					padding-right: 15vw;
				  }
				background-size: 7vw;
				background-repeat: no-repeat;

				&.expanded {
					background-image: url("/assets/img/icons/minus.png");
					margin-bottom: 0;

					+ .answer {
						display: block;
					}

				}

			}

			.answer {
				padding: 5vw;
				margin: 0;
				background-color: rgba(34, 141, 150, 0.08);
				margin-bottom: 20vw;
				display: none;
				
				p, li {
					font-weight: normal;
					margin-bottom: 4.75vw;
				}
				
			}
			
		}
		
		
	}
	
	
	
	
	/* ----------------------------------------------------------------------------------------------------------------*/	

	/* Desktop styles */
	@media screen and (min-width: $desktop) {
		
		.hero {
			margin-top: 97px;
			margin-bottom: 0;
			padding: 150px 0 196px;
			background-image: url("/assets/img/backgrounds/FAQ-hero.jpg");

			.header-container {
				max-width: 575px;
			}
			
			h1 {
				font-size: 38px;
				line-height: 44px;
				margin: 0;
			}
		
		}
		
		#questions {
			
			margin-bottom: 100px;

			h3 {
				font-size: 24px;
				line-height: 30px;
				max-width: 600px;
				margin: 40px 0 55px;
			}

			.content {

				#expand-all {
					right: 25px;
					top: 75px;
				}
				
				.expand-button {
					font-size: 20px;
					line-height: 24px;
					padding: 30px 70px 30px 25px;
					margin: 0 0 20px 0;
					background-position: right 25px center;
					background-size: 25px;
				}

				.answer {
					padding: 25px;
					padding-bottom: 12px;
					margin-bottom: 20px;

					p, li {
						margin-bottom: 15px;
					}
					
					ul {
						margin: 0;
					}

				}

			}

		}

	}
	
	
}