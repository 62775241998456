/* Scss Document */

@media screen and (min-width: $desktop) {

	.content {
		max-width: 1100px;
		margin: 0 auto;
		padding: 0 25px;
		position: relative;

		&.thin {
			padding: 0 25px;
		}

		@media only screen and (min-width: 2400px) {
			.header-container h1 {
				margin-bottom: 62px;
			}
		}

	}

	.desktop-only {
		display: inherit;
	}

	span.desktop-only,
	br.desktop-only {
		display: inline;
	}

	table.desktop-only {
		display: table;
	}


	.mobile-only {
		display: none !important;
	}


	/* REFERENCE */
	#reference {
		height: 5211px;
		background-image: url("/assets/img/REFERENCE-desktop.jpg");
		background-size: 1600px auto;
		display: none;
	}

	/* HEADER */
	header {
		padding-bottom: 0;
		width: 100%;
		background-image: url("/assets/img/backgrounds/header-desktop.gif");
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}

	#top-links {
		border-radius: 0;
		padding: 14px 25px;
		margin-top: 0;
		display: flex;
		flex-wrap: nowrap;
		justify-content: flex-end;
		background-color: transparent;
		position: absolute;
		top: 0px;
		right: 0px;

		p {
			font-size: 11px;
			line-height: 14px;
			margin-left: 35px;

			&#isi-link {
				display: inline;
			}

			&#hcp {
				display: inline;
				width: auto;
			}

		}

	}

	.hero {
		margin-bottom: 0px;
		margin-top: 95px;
		padding-bottom: 100px;

		background-image: url("/assets/img/backgrounds/hero-desktop.png");
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;

		.header-container {
			max-width: 450px;
		}

		a.button {
			max-width: 300px;
		}

	}

	#divider-dots {
		display: block;
		width: 100%;
		height: 5px;
		background-image: url("/assets/img/backgrounds/orange-dots.png");
		background-size: auto 5px;
		background-repeat: repeat-x;
		background-position: center;
		margin-bottom: 40px;
	}


	/* HOME */
	.home {

		h1 {
			margin-right: 0;
			padding-top: 60px;
			max-width: 400px;
		}

		p.header-text {
			margin-bottom: 25px;
		}

	}

	.callouts {
		margin-top: 100px;
		margin-bottom: 100px;

		display: flex;
		justify-content: space-between;

	}

	.callout {
		border-radius: 39px;
		padding-bottom: 50px;
		margin: 0 15px 50px;


		img.icon {
			margin: 0 auto 50px;
			width: 128px;
			top: -64px;

			+p {
				margin: -64px 25px 15px;
			}

		}

		img.divider {
			margin: 15px auto;
		}

		p {
			font-size: 18px;
			line-height: 24px;
			margin: 10px 15px 10px;
		}

		a.button {
			margin: 0 25px;
			font-size: 18px;
			line-height: 18px;
			padding: 18px;
		}

		&.first {
			margin-left: 0;
		}

		&.third {
			margin-right: 0;
		}

	}



	/* COMMON */
	.blue-strip {
		padding: 20px 0;
		margin-bottom: 50px;

		.flex {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		img.icon {
			display: block;
			width: 190px;
			margin: 0 20px;
		}

		p {
			font-size: 20px;
			line-height: 28px;
			text-align: left;
			margin: 0 0 15px;
		}

		a.button {
			font-size: 20px;
			line-height: 20px;
			padding: 10px;
			max-width: 150px;
			margin: 0;
		}

	}


	#references {
		margin-top: 50px;
		margin-bottom: 30px;
		padding: 0 0;
	}

	#isi {
		padding: 0 0;

		p,
		li {
			line-height: 20px;
		}

		.content {
			position: relative;

			#isi-target {
				position: absolute;
				top: -115px;
			}

			#risk-information {
				position: relative;
				top: -115px;
			}

		}
	}

	/*#static-isi {
		position: fixed;
		bottom: 0px;
		left: 0px;
		width: 100vw;
		height: 38vw;
		background-color: white;
		display: none;
	}*/

	footer {
		padding: 50px 0;
		margin-top: 50px;

		.content {
			display: flex;
			justify-content: flex-start;
			align-items: center;
		}

		#alk-logo img {
			width: 225px;
			height: auto;
			margin-right: 70px;
		}

		#footer-content {
			margin: 0 0 0;
		}

		p {
			font-size: 15px;
			line-height: 24px;
			margin-bottom: 20px;

			&.copyright {
				font-size: 15px;
				line-height: 24px;
				margin-bottom: 0;
			}

		}

		a {
			margin: 0 10px;

			&.no-left {
				margin-left: 10px;
			}

			&#risk-link {
				margin-left: 0;
			}

		}

	}

	.grey-bar {
		padding: 30px 0 20px;
		margin-bottom: 50px;
	}

	.rounded-blue {
		border: 15px solid transparent;
		padding: 20px;
		border-radius: 50px;
		border-image: url("/assets/img/patterns/blue-dotted-rectangle.png") 35 round;
		-moz-border-image: url("/assets/img/patterns/blue-dotted-rectangle.png") 27 round;
		-webkit-border-image: url("/assets/img/patterns/blue-dotted-rectangle.png") 27 round;
		-o-border-image: url("/assets/img/patterns/blue-dotted-rectangle.png") 27 round;
	}

	img.check {
		width: 92px;
		margin: 0 auto 20px;
	}

	#guidelines {

		padding: 50px 0 80px;

		background-image: url("/assets/img/backgrounds/white-dotted-line.png"), url("/assets/img/backgrounds/white-dotted-line.png");
		background-position: top 50px left, bottom 70px right;

		p {
			font-size: 18px;
			line-height: 24px;
			margin: 30px auto;
			max-width: 750px;
		}

	}

	#pdfs,
	#pdfs2 {

		padding: 65px 0 40px;

		.icon {
			height: 105px;
			width: auto;
			margin: 0 auto 30px;

			&.syringe {
				width: 109px;
				margin-bottom: 30px;
			}

		}

		.pdf-row {
			display: flex;
			justify-content: space-around;
		}

		.pdf-link {
			border: 15px solid transparent;
			padding: 30px 20px;
			border-radius: 25px;
			border-image: url("/assets/img/patterns/pdf-outline.png") 30 round;
			-moz-border-image: url("/assets/img/patterns/pdf-outline.png") 27 round;
			-webkit-border-image: url("/assets/img/patterns/pdf-outline.png") 27 round;
			-o-border-image: url("/assets/img/patterns/pdf-outline.png") 27 round;

			margin-bottom: 0;
			width: 40%;
			max-width: 305px;

			p {
				margin-bottom: 40px;
			}

			a.button {

				&.learn {
					max-width: 160px;
					margin-top: 66px;
				}

			}

		}

	}



	.expand-button {
		font-size: 24px;
		line-height: 24px;
		padding: 37px 25px;
		margin: 0 0 40px 0;
		background-position: right 25px center;
		background-size: 40px;
		cursor: pointer;
	}


	.error-404 {

		.hero {
			margin: 130px 0 60px;
			padding: 50px 0;
			background-color: #FEEBE7;
			background-image: none;

			.content {
				display: flex;
				justify-content: center;
				align-items: center;
			}

			img.icon {
				max-width: 400px;
			}

			a.button {
				max-width: 160px;
			}

		}

	}

	.error-500 {

		.hero {
			margin: 130px 0 60px;
			padding: 50px 0;
			background-color: #FEEBE7;
			background-image: none;

			.content {
				display: flex;
				justify-content: center;
				align-items: center;
			}

			img.icon {
				max-width: 480px;
			}

		}

	}




}